<template>
  <v-dialog
    v-model="show"
    width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Adicionar/Editar horário
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="workShift.shift"
                :items="shifts"
                item-value="id"
                item-text="description"
                hide-details="auto"
                label="Turno"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="workShift.start"
                v-mask="'##:##'"
                label="Hora Inicial"
                hide-details="auto"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="workShift.end"
                v-mask="'##:##'"
                label="Hora Final"
                hide-details="auto"
                autocomplete="off"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          @click="save"
        >
          <v-icon
            v-text="'mdi-content-save'"
            left
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      editWorkShift: {
        type: Object,
        default: () => ({}),
      },
    },

    data () {
      return {
        shifts: [
          {
            id: 1,
            description: 'Manhã',
          },
          {
            id: 2,
            description: 'Tarde',
          },
          {
            id: 3,
            description: 'Noite',
          },
        ],
        isEditing: false,
        workShift: {}
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return
        const removedObserver = JSON.parse(JSON.stringify(this.editWorkShift))
        if (Object.keys(removedObserver).length > 0) {
          this.isEditing = true
          this.workShift = this.editWorkShift
        } else {
          this.isEditing = false
        }
      },
    },

    methods: {
      async save () {
        const eventToEmit = this.isEditing ? 'edit' : 'add'
        this.$emit(eventToEmit, this.workShift)
        this.workShift = {}
      },
    },

  }
</script>
